<template>
  <div class="renwuList">
    <!-- 顶部导航 -->
    <!-- <van-nav-bar class="topnav" title="任务列表" @click-left="toBack" :border="false" /> -->
    <!-- 文章列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <div class="artList">
      <van-list
        v-model:loading="loading"
        class="article1"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : '没有更多了'"
        @load="onLoad"
      >
      <div
              class="taskItem"
              v-for="(item,idx) in list"
              :key="idx"
              @click="toTaskDetail(item)"
            >
              <div>
                <img style="height:1.87rem;width:2.88rem;object-fit: cover;background:#f8f8f8;" :src="adddom(item.thumb)" />
              </div>
              <div>
                <h1>{{item.title}}</h1>
                <!-- <h2>领取任务人数：{{item.ling_num}}人</h2> -->
                <p style="width:100%;justify-content: space-between;margin-top: 0.1rem;position: absolute;left: 0;bottom: 0;">
                  <!-- <img src="../../public/statics/images/index/red.png" /> -->
                  {{timestampToTime(item.created_at, 1)}}
                  <em>{{item.ling_num > 10000 ? (item.ling_num/10000).toFixed(1)+' 万': item.ling_num}}人次转发</em>
                  <span style="">{{item.hit > 10000 ? (item.hit/10000).toFixed(1)+' 万': item.hit}} 人次浏览</span>
                  <!-- <span style="display: inline-block;line-height: 0.5rem;background: #F6F6F6;font-size: 0.25rem;color: rgba(0, 0, 0, 0.6);padding: 0 0.1rem;">{{item.hit > 1000 ? (item.hit/1000).toFixed(2) + ' k' : item.hit}} 人次浏览</span> -->
                </p>
              </div>
            </div>
      </van-list>
      <van-empty v-if="!loading && list.length == 0" description="暂无数据" />
    </div>
    </van-pull-refresh>
    <!-- 任务列表 -->
    <div class="rw" v-show="rwShow" @click="toTask">
      <img src="../../public/statics/images/my/myrenwu.png" alt />
    </div>
    <!-- 底部导航 -->
    <index-footer></index-footer>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import indexFooter from "./footer.vue";
import utils from '@/api/Utils'
export default {
  data() {
    return {
      rwShow: false,
      pages: {
        page: 1,
        size: 10
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  components: {
    indexFooter
  },
  created() {
    this.memberInfo()
  },
  activated() {
    this.memberInfo()
  },
  mounted() {
    $('.rw').unbind().on("touchstart", function(e) {
      $('body').css({'overflow':'hidden'})
        var e = e || window.event;
        console.log(e);
        var positionDiv = $(this).offset();
        var scrollTop = window.pageYOffset //用于FF
          || document.documentElement.scrollTop
          || document.body.scrollTop
          || 0;
        console.log(scrollTop)
        var distenceY =
          e.originalEvent.targetTouches[0].pageY - positionDiv.top + scrollTop
        $(document).on("touchmove", function(e) {
          var e = e || window.event;
          var y = e.originalEvent.targetTouches[0].pageY - distenceY;
          let height = $(".topnav").outerHeight(true) + 15;
          if (y < height) {
            y = height;
          } else if (y > $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.rw').outerHeight(true) - 15) {
            y = $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.rw').outerHeight(true) - 15
          }

          $(".rw").css({
            top: y + "px"
          })
        });
        $(".rw").on("touchend", function(e) {
          $('body').css({'overflow':'auto'})
          $(document).unbind("touchmove");
        });
      });
  },
  methods: {
    memberInfo() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.index.memberInfo(ps).then(function(response) {
        if (response.code === 200) {
          localStorage.setItem('priv', JSON.stringify(response.result.priv))
          // 不是发生人不显示，我的任务悬浮按钮
          if (response.result.speaker_id == 0 && response.result.speaker == null) {
            that.rwShow = false
            // that.toPath('/apply',{})
          } else if (response.result.speaker && response.result.speaker.status == 1){
            that.rwShow = true
          }
        }
      })
    },
    timestampToTime (timestamp, type) {
      if (!timestamp) return
      return utils.timestampToTime(timestamp, type)
    },
    adddom (src) {
      if (src == '' || src == undefined || src == null) {
        console.log('src地址有问题')
        return ''
      }
      if (src.substr(0, 4).toLowerCase() === 'http') {
        return src
      }
      return this.$imgurl + src
    },
    toBack() {
      this.$router.go(-1);
    },
    onRefresh() {
      let that = this
      that.refreshing = false;
      that.list = []
      that.loading = true;
      that.finished = false;
      that.pages.page = 1
      that.onLoad()
    },
    onLoad() {
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        key: that.pages.key,
        page: that.pages.page,
        size: that.pages.size
      };
      that.$api.index.dutyList(params).then(function(res) {
        if (res.code == 200) {
          console.log(res.result)
          that.list = that.list.concat(res.result);
          that.pages.page++;
          // 加载状态结束
          that.loading = false;
          if (res.result.length < 1) {
            that.finished = true;
          }
        }
      });
    },
    toTaskDetail(item) {
      console.log(item);
      this.toPath("/taskDetail", {id:item.id});
    },
    // 点击任务列表
    toTask() {
      this.$router.push({
        path: "/myRenWu"
      });
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    }
  }
};
</script>

<style scoped>
.renwuList {
  padding-bottom: 3rem;
}
.renwuList .taskItem {
    width: 100%;
    min-height: 2.4rem;
    background: #ffffff;
    margin-bottom: 0.35rem;
    display: flex;
    padding: 0.27rem;
}
.renwuList .taskItem div:nth-child(1) {
    width: 2.88rem;
    height: 1.87rem;
    border-radius: 10px;
    margin-right: 0.4rem;
}
.renwuList .taskItem div:nth-child(2) {
    flex: 1;
    position: relative;
}
.renwuList .taskItem div:nth-child(2) h1 {
    font-size: 0.35rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.renwuList .taskItem div:nth-child(2) h2 {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 0.15rem;
  margin-bottom: 0.1rem;
}
.renwuList .taskItem div:nth-child(2) p {
    font-size: 0.3rem;
    line-height: 0.3rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    display: inline-flex;
    align-items: center;
}
.renwuList .rw {
    position: fixed;
    width: 1.89rem;
    top: 8rem;
    right: 0.4rem;
}
.renwuList .van-pull-refresh{
  overflow: visible;
}
</style>