<template>
  <div class="special">
    <!-- 标签 -->
    <van-tabs v-model:active="active" swipe-threshold="3" line-width="15px" swipeable title-active-color="#0B47AC" @change="onClickTab">
      <van-tab v-for="(item,idx) in cateList" :key="'cate'+idx" :title="item.name" :name="item.id">
        <div class="spec_list" v-if="list.length > 1">
        <!-- <div class="spec_list"> -->
          <van-list>
            <div class="spec_item" style="padding:0;" @click="toDetail(item)" v-for="(item,index) in list" :key="'cate'+index">
              <div class="des">
                {{item.title}}
              </div>
              <div class="pic" style="width:100%;height: 4.53rem;border-radius: 10px;position: relative;">
                <img style="display:block;" :src="adddom(item.thumb)" alt />
                <img src="../../public/statics/images/index/1.jpg" alt />
                <h6 v-if="item.name_status == 1">{{item.name}}</h6>
              </div>
              <div class="time">{{item.publish_at}}</div>
            </div>
          </van-list>
          <van-empty v-if="list && list.length == 0" description="暂无数据" />
        </div>
        <special-list style="margin-top:0.2rem;" v-if="active && list.length == 1" :footer="true" :id="list[0].id"></special-list>
      </van-tab>
    </van-tabs>
    <!-- 底部导航 -->
    <index-footer></index-footer>
    <div class="indicator" v-if="cateList.length>5">
      <!-- <van-icon name="ellipsis" /> -->
      <!-- 更多 -->
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import indexFooter from "./footer.vue";
import specialList from "./specialList.vue";
export default {
  data() {
    return {
      active: '',
      cateList: [],
      list: '',
      loading: false,
      finished: false,
      refreshing: false,
      params: {
        cate_id: '',
        page: 1,
        size: 10
      },
      shareConfig: '',
      getCodeUrl: "http://speaker.shanmuzhi.com/api/wxcode?url=special",
      memberUrl: "http://speaker.shanmuzhi.com/#/special"
    };
  },
  components: {
    indexFooter,
    specialList
  },
  activated() {
  },
  created() {
    // 分享
    if (this.$route.query.active) {
      this.active = parseInt(this.$route.query.active)
    }
    this.getCodeUrl = this.$publicData.domain + `api/wxcode?url=special?active=${this.active}`
    this.memberUrl = this.$publicData.domain + `#/special?active=${this.active}`
    let that = this;
    var token = Cookies.get("spToken");
    if (token && token != undefined) {
      // 有token 直接展示
      this.getCate()
      return;
    }
    // 判断是否已注册，如果注册则正常微信登录，如果没有则先注册。
    var codeArr = this.getUrlCode();
    // 获取code
    if (codeArr["code"] != undefined) {
      var code = codeArr["code"];
      var params = {
        code: code
      };
      // 绑定
      this.$api.index.wxlogin(params).then(res => {
        if (res.code == 200) {
          Cookies.set("spToken", res.result.token, { expires: 365 });
          localStorage.setItem("priv", JSON.stringify(res.result.priv));
          that.getCate()
        }
      });
    } else {
      window.location.href = this.getCodeUrl;
    }
  },
  watch:{
    'active':function(val,old){
      let that = this
      this.list = ''
      const shareUrl = location.href + `?active=${that.active}`
      // console.log(shareUrl)
      var shareDataA = {
        title: that.$publicData.shareTitle, // 分享标题
        // title: "我已成为运城发声人，我为家乡代言", // 分享标题
        desc: that.$publicData.shareDesc, // 分享描述
        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: that.$publicData.shareImg, // 分享图标
        success: function() {},
        cancel: function(e) {}
      };

      var shareDataB = {
        title: that.$publicData.shareTitle, // 分享标题
        // title: "我已成为运城发声人，我为家乡代言", // 分享标题
        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: that.$publicData.shareImg, // 分享图标
        success: function() {},
        cancel: function(e) {}
      };
      wx.updateAppMessageShareData(shareDataA); // 分享给朋友
      wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
    }
  },
  updated(){
    this.$nextTick(function () {
      //  仅在整个视图都被重新渲染之后才会运行的代码    
      $('.van-tabs__nav--line.van-tabs__nav--complete').scroll(function() {
        let width = 0
        $.map( $('.van-tab'), function(element){
          width=width+$(element).outerWidth(true)
        });
        if ($('.van-tabs__wrap--scrollable .van-tabs__nav').scrollLeft() >= width - $('.van-tabs__wrap--scrollable .van-tabs__nav').width()) {
          $('.special div.indicator').fadeOut(300)
        } else {
          $('.special div.indicator').fadeIn(300)
        }
      }) 
    })
  },
  mounted() {
  },
  methods: {
    shareFun() {
      var that = this;
      var tempurl = location.href;
      const shareUrl = location.href + `?active=${that.active}`
      // console.log(shareUrl);
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // 分享
          wx.ready(function() {
            var shareDataA = {
              title: that.$publicData.shareTitle, // 分享标题
              // title: "我已成为运城发声人，我为家乡代言", // 分享标题
              desc: that.$publicData.shareDesc, // 分享描述
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };

            var shareDataB = {
              title: that.$publicData.shareTitle, // 分享标题
              // title: "我已成为运城发声人，我为家乡代言", // 分享标题
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
          });
        }
      });
    },
    // 截取url中的code方法
    getUrlCode() {
      var allUrl = location.href;
      var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    toDetail(item) {
      this.toPath('/specialList',{id: item.id})
    },
    onClickTab(e){
      let that = this
      that.onLoad()
    },
    adddom (src) {
      if (src == '' || src == undefined || src == null) {
        console.log('src地址有问题')
        return ''
      }
      if (src.substr(0, 4).toLowerCase() === 'http') {
        return src
      }
      return this.$imgurl + src
    },
    getCate() {
      let that = this
      let ps = {
        token: Cookies.get('spToken')
      }
      that.$api.special.getCate(ps).then(res => {
        if (res.code == 200) {
          that.cateList = res.result
          that.active = that.cateList[0].id
          
          if (that.$route.query.active) {
            that.active = parseInt(that.$route.query.active)
            that.onLoad()
          } else {
            that.shareFun()
            that.onLoad()
          }
        }
      })
    },
    onLoad() {
      let that = this
      if (!that.active) {
        return
      }
      that.params.cate_id = that.active
      that.params.token = Cookies.get('spToken')
      that.$api.special.subCate(that.params).then(function (response) {
        if (response.code === 200) {
          that.list = [].concat(response.result)
        }
      })
    },
    toPath (url, obj) {
      if (this.$route.fullPath == url) {
        return
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        })
        window.location.reload()
        return
      }
      this.$router.push({
        path: url,
        query: obj
      })
    },
  }
};
</script>

<style>
.special .van-pull-refresh{
  overflow: auto;
  height: 60vh;
}
.special .spec_list{
  margin-top: 0;
  padding-top: 0.4rem;
}
.special .spec_list .spec_item{
  margin-bottom:0.4rem;
}
.special .spec_list .spec_item .pic h6{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 0.45rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 0.8rem;
  padding: 0 0.3rem;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.special div.indicator{
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 1;
  margin: auto 0;
  cursor: pointer;
  width: 2em;
  height: 42px;
  line-height: 42px;
  border-radius: 2px 0 0 2px;
  background: rgba(255,255,255,.9);
  /* background: linear-gradient(to left, rgba(255,255,255,0.9), rgba(255,255,255,0)); */
  text-align: center;
  font-size: 20px;
}
.special div.indicator img {
    width: 6px;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.special .van-tabs__content--animated{
  height: calc(100vh - 44px);
}
</style>