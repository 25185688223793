<template>
  <div class="fs_article">
    <!-- 顶部导航 -->
    <van-nav-bar class="topnav" title="原创文章" left-arrow @click-left="toBack" :border="false" />
    <!-- 文章列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <div class="artList">
      <van-list
        v-model:loading="loading"
        class="article1"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : '没有更多了'"
        @load="onLoad"
      >
      <div class="artItem" @click="toPath('/articleDetail',{id:item.id})" v-for="(item,index) in list" :key="'list'+index">
        <div class="top">
          <div class="pic">
            <!-- <img src="../../public/statics/images/index/1.jpg" alt /> -->
            <img :src="adddom(item.thumb)" alt />
          </div>
          <div class="con">
            <div class="title slh">{{item.title}}</div>
            <div class="des">{{item.content}}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="user">
            <!-- <img src="../../public/statics/images/index/avator.png" alt /> -->
            <img :src="adddom(item.speaker.thumb)" alt />
            <span>{{item.speaker ? item.speaker.name : ''}}</span>
          </div>
          <div class="time">{{item.speaker ? item.speaker.created_at : ''}}</div>
        </div>
      </div>
      </van-list>
      <van-empty v-if="!loading && list.length == 0" description="暂无数据" />
    </div>
    </van-pull-refresh>
    <!-- 任务列表 -->
    <div class="rw" @click="toTask">
      <img src="../../public/statics/images/my/renwu.png" alt />
    </div>
    <!-- 底部导航 -->
    <index-footer></index-footer>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import indexFooter from "./footer.vue";
export default {
  data() {
    return {
      params: {
        page: 1,
        size: 10
      },
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  components: {
    indexFooter
  },
  created() {
  },
  mounted() {
    $('.rw').unbind().on("touchstart", function(e) {
      $('body').css({'overflow':'hidden'})
        var e = e || window.event;
        console.log(e);
        var positionDiv = $(this).offset();
        var scrollTop = window.pageYOffset //用于FF
          || document.documentElement.scrollTop
          || document.body.scrollTop
          || 0;
        console.log(scrollTop)
        var distenceY =
          e.originalEvent.targetTouches[0].pageY - positionDiv.top + scrollTop
        $(document).on("touchmove", function(e) {
          var e = e || window.event;
          var y = e.originalEvent.targetTouches[0].pageY - distenceY;
          let height = $(".topnav").outerHeight(true) + 15;
          if (y < height) {
            y = height;
          } else if (y > $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.rw').outerHeight(true) - 15) {
            y = $(window).get(0).innerHeight - $('.footer').outerHeight(true) - $('.rw').outerHeight(true) - 15
          }

          $(".rw").css({
            top: y + "px"
          })
        });
        $(".rw").on("touchend", function(e) {
          $('body').css({'overflow':'auto'})
          $(document).unbind("touchmove");
        });
      });
  },
  methods: {
    adddom (src) {
      if (src == '' || src == undefined || src == null) {
        console.log('src地址有问题')
        return ''
      }
      if (src.substr(0, 4).toLowerCase() === 'http') {
        return src
      }
      return this.$imgurl + src
    },
    toBack() {
      this.$router.go(-1);
    },
    onRefresh() {
      let that = this
      that.refreshing = false;
      that.list = []
      that.loading = true;
      that.finished = false;
      that.params.page = 1
      that.onLoad()
    },
    onLoad() {
      let that = this
      that.params.token = Cookies.get('spToken')
      that.$api.article.list(that.params).then(function (response) {
        if (response.code === 200) {
          console.log(response)
          that.list = that.list.concat(response.result)
          that.params.page++
          // 加载状态结束
          that.loading = false
          // 数据全部加载完成
          // console.log(response.result.length)
          if (response.result.length < 1) {
            that.finished = true
          }
        }
      })
    },
    // 点击任务列表
    toTask() {
      this.$router.push({
        path: "/taskList"
      });
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    }
  }
};
</script>

<style>
.article .van-pull-refresh{
  overflow: visible;
}
</style>